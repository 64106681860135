import { Terminal } from './terminal'

export enum Section {
  About = 'about',
  Expertise = 'expertise',
  Contacts = 'contacts',
}

type Terminals = {
  terminalAbout: Terminal
  terminalExpertise: Terminal
  terminalContacts: Terminal
}

// onScrollUp - changes sections in sequence 3 - 2 - 1.
export const onScrollUp = (currentSection: Section, terminals: Terminals): Section => {
  const { terminalAbout, terminalExpertise, terminalContacts } = terminals
  switch (currentSection) {
    case Section.About:
      return currentSection
    case Section.Expertise:
      activateSection(Section.About, {
        terminalAbout,
        terminalExpertise,
        terminalContacts,
      })
      return Section.About
    case Section.Contacts:
      activateSection(Section.Expertise, {
        terminalAbout,
        terminalExpertise,
        terminalContacts,
      })
      return Section.Expertise
    default:
      return currentSection
  }
}

// onScrollDown - change sections in sequence 1 - 2 - 3.
export const onScrollDown = (currentSection: Section, terminals: Terminals): Section => {
  const { terminalAbout, terminalExpertise, terminalContacts } = terminals
  switch (currentSection) {
    case Section.About:
      activateSection(Section.Expertise, {
        terminalAbout,
        terminalExpertise,
        terminalContacts,
      })
      return Section.Expertise
    case Section.Expertise:
      activateSection(Section.Contacts, {
        terminalAbout,
        terminalExpertise,
        terminalContacts,
      })
      return Section.Contacts
    case Section.Contacts:
      return currentSection
    default:
      return currentSection
  }
}

// activateSection - activates section by applying styles to section, title, intro, content, and terminal
export const activateSection = (sectionName: Section, terminals: Terminals) => {
  const { terminalAbout, terminalExpertise, terminalContacts } = terminals

  const sectionAbout = document.getElementById('section-about')
  const sectionExpertise = document.getElementById('section-expertise')
  const sectionContacts = document.getElementById('section-contacts')

  // get titles
  const titleAbout = document.getElementById('title-about')
  const titleExpertise = document.getElementById('title-expertise')
  const titleContacts = document.getElementById('title-contacts')

  // get intros
  const introAbout = document.getElementById('intro-about')
  const introExpertise = document.getElementById('intro-expertise')
  const introContacts = document.getElementById('intro-contacts')

  // get content
  const contentAbout = document.getElementById('content-about')
  const contentExpertise = document.getElementById('content-expertise')
  const contentContacts = document.getElementById('content-contacts')

  switch (sectionName) {
    case Section.About:
      // set section active styles
      sectionAbout!.classList.add('section-active')
      sectionExpertise!.classList.remove('section-active')
      sectionContacts!.classList.remove('section-active')
      // set section inactive styles
      sectionAbout!.classList.remove('section-inactive')
      sectionExpertise!.classList.add('section-inactive')
      sectionContacts!.classList.add('section-inactive')

      // set title active styles
      titleAbout!.classList.add('section-title-active')
      titleExpertise!.classList.remove('section-title-active')
      titleContacts!.classList.remove('section-title-active')

      // set intro active styles
      introAbout!.classList.add('section-intro-active')
      introExpertise!.classList.remove('section-intro-active')
      introContacts!.classList.remove('section-intro-active')

      // set content active styles
      contentAbout!.classList.add('section-content-active')
      contentExpertise!.classList.remove('section-content-active')
      contentContacts!.classList.remove('section-content-active')

      // init or clean terminals
      terminalAbout.cleanTerminal()
      terminalAbout.init()
      terminalExpertise.cleanTerminal()
      terminalContacts.cleanTerminal()

      break
    case Section.Expertise:
      // set section active styles
      sectionAbout!.classList.remove('section-active')
      sectionExpertise!.classList.add('section-active')
      sectionContacts!.classList.remove('section-active')
      // set section inactive styles
      sectionAbout!.classList.add('section-inactive')
      sectionExpertise!.classList.remove('section-inactive')
      sectionContacts!.classList.add('section-inactive')

      // set title active styles
      titleAbout!.classList.remove('section-title-active')
      titleExpertise!.classList.add('section-title-active')
      titleContacts!.classList.remove('section-title-active')

      // set intro active styles
      introAbout!.classList.remove('section-intro-active')
      introExpertise!.classList.add('section-intro-active')
      introContacts!.classList.remove('section-intro-active')

      // set content active styles
      contentAbout!.classList.remove('section-content-active')
      contentExpertise!.classList.add('section-content-active')
      contentContacts!.classList.remove('section-content-active')

      // init or clean terminals
      terminalAbout.cleanTerminal()
      terminalExpertise.cleanTerminal()
      terminalExpertise.init()
      terminalContacts.cleanTerminal()

      break
    case Section.Contacts:
      // set section active
      sectionAbout!.classList.remove('section-active')
      sectionExpertise!.classList.remove('section-active')
      sectionContacts!.classList.add('section-active')
      // set section inactive styles
      sectionAbout!.classList.add('section-inactive')
      sectionExpertise!.classList.add('section-inactive')
      sectionContacts!.classList.remove('section-inactive')

      // set title active styles
      titleAbout!.classList.remove('section-title-active')
      titleExpertise!.classList.remove('section-title-active')
      titleContacts!.classList.add('section-title-active')

      // set intro active styles
      introAbout!.classList.remove('section-intro-active')
      introExpertise!.classList.remove('section-intro-active')
      introContacts!.classList.add('section-intro-active')

      // set content active styles
      contentAbout!.classList.remove('section-content-active')
      contentExpertise!.classList.remove('section-content-active')
      contentContacts!.classList.add('section-content-active')

      // init or clean terminals
      terminalAbout.cleanTerminal()
      terminalExpertise.cleanTerminal()
      terminalContacts.cleanTerminal()
      terminalContacts.init()

      break
    default:
      throw 'Unexpected section name.'
  }
}
