import { TerminalElementType } from './terminal'

export const terminalSettings = {
  about: {
    fontFamily: 'var(--font-secondary)',
    fontSize: 'min(2vh, 2.2rem)',
    fontColor: 'var(--cl-term-about-font)',

    terminalId: 'terminal-about',
    terminalBgColor: 'var(--cl-term-about-bg)',
    terminalSecondaryBgColor: 'var(--cl-term-about-bg-secondary)',
    terminalHeight: '100%',
    terminalWidth: '100%',
    terminalPadding: '4rem',
    terminalBlink: false,

    terminalFrameRate: 25,
    paragraphMargin: '0px 0px 2rem 0px',

    outputText: [
      [
        {
          type: TerminalElementType.Text,
          content:
            'Greetings, I am Artem, an IT enthusiast and innovative creator from Ukraine!',
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content:
            'I invest considerable effort in continuously expanding my expertise in systems engineering and cloud technologies.',
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content:
            'In addition, I derive great pleasure from engaging in video games alongside my friends and cherishing moments with my beloved family.',
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content:
            'As a personal pursuit, I explore the realms of digital art and acquire proficiency in diverse languages.',
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content: "It's a pleasure to meet you!",
        },
      ],
    ],
    parentElement: document.getElementById('content-about'),
  },

  expertise: {
    fontFamily: 'var(--font-secondary)',
    fontSize: 'min(2vh, 2.2rem)',
    fontColor: 'var(--cl-term-expertise-font)',

    terminalId: 'terminal-expertise',
    terminalBgColor: 'var(--cl-term-expertise-bg)',
    terminalSecondaryBgColor: 'var(--cl-term-expertise-bg-secondary)',
    terminalHeight: '100%',
    terminalWidth: '100%',
    terminalPadding: '2rem',
    terminalBlink: false,

    terminalFrameRate: 50,
    paragraphMargin: '0px 0px 2rem 0px',

    outputText: [
      [
        {
          type: TerminalElementType.Text,
          content:
            'Through four years of experience in designing, building, and deploying cloud-based web solutions, I have nurtured an unwavering passion for this domain.',
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content:
            'Clients - employment of HTML, CSS, JS (TS), React, and Next to fulfil varied needs.',
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content:
            'Servers - development of scalable microservices and containerized workloads utilizing Go programming language.',
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content:
            'Clouds - infrastructure provisioning with IaC tools, configuration, monitoring, and maintenance on AWS.',
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content:
            'DevOps - automation of SDLC processes, CI/CD with Jenkins, and GitHub Actions.',
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content: 'Intrigued?',
        },
      ],
    ],
    parentElement: document.getElementById('content-expertise'),
  },

  contacts: {
    fontFamily: 'var(--font-secondary)',
    fontSize: 'min(2vh, 2.2rem)',
    fontColor: 'var(--cl-term-contacts-font)',

    terminalId: 'terminal-contacts',
    terminalBgColor: 'var(--cl-term-contacts-bg)',
    terminalSecondaryBgColor: 'var(--cl-term-contacts-bg-secondary)',
    terminalHeight: '100%',
    terminalWidth: '100%',
    terminalPadding: '2rem',
    terminalBlink: false,

    terminalFrameRate: 50,
    paragraphMargin: '0px 0px 2rem 0px',

    outputText: [
      [
        {
          type: TerminalElementType.Text,
          content: `Feel free to reach out; I eagerly await our connection!`,
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content: 'Email: ',
        },
        {
          type: TerminalElementType.Link,
          content: 'artem_nikolaiev@yahoo.com',
          href: 'artem_nikolaiev@yahoo.com',
          mailto: true,
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content: 'GitHub: ',
        },
        {
          type: TerminalElementType.Link,
          content: 'github.com/artemnikolaiev',
          href: 'https://github.com/artemnikolaiev',
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content: 'Telegram:  ',
        },
        {
          type: TerminalElementType.Link,
          content: '@artem_nikolaiev           ',
          href: 'https://t.me/artem_nikolaiev',
        },
      ],

      /*
       [
        {
          type: TerminalElementType.Text,
          content: `Feel free to reach out; I eagerly await our connection!`,
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content: 'Email: ',
        },
        {
          type: TerminalElementType.Link,
          content: 'artem_nikolaiev@yahoo.com',
          href: 'artem_nikolaiev@yahoo.com',
          mailto: true,
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content: 'GitHub: ',
        },
        {
          type: TerminalElementType.Link,
          content: 'github.com/artemnikolaiev',
          href: 'https://github.com/artemnikolaiev',
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content: 'Facebook: ',
        },
        {
          type: TerminalElementType.Link,
          content: 'facebook.com/artemnikolaiev2911',
          href: 'https://www.facebook.com/artemnikolaiev2911',
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content: 'Telegram:  ',
        },
        {
          type: TerminalElementType.Link,
          content: '@artem_nikolaiev           ',
          href: 'https://t.me/artem_nikolaiev',
        },
      ],
      */

      /*
      [
        {
          type: TerminalElementType.Text,
          content: 'Twitter: ',
        },
        {
          type: TerminalElementType.Link,
          content: 'twitter.com/artem_nikolaiev',
          href: 'https://twitter.com/artem_nikolaiev',
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content: 'YouTube: ',
        },
        {
          type: TerminalElementType.Link,
          content: 'youtube.com/@generalist2911',
          href: 'https://www.youtube.com/@generalist2911',
        },
      ],
      [
        {
          type: TerminalElementType.Text,
          content: 'Blog: ',
        },
        {
          type: TerminalElementType.Link,
          content: 'blog.artemnikolaiev.com',
          href: 'https://blog.artemnikolaiev.com/',
        },
      ],
      */
    ],
    parentElement: document.getElementById('content-contacts'),
  },
}
