/**
 * TerminalElement - is element, that needed to be rendered in terminal in special way,
 * for example this can be anchor link, or emoji.
 * @param type - terminal element type can, can be link or emoji.
 * @param content - terminal element text content.
 * @param href - terminal element href for link elements (optional).
 * @param mailto - terminal element mailto for link elements (optional).
 */

export enum TerminalElementType {
  Text = 'text',
  Link = 'link',
  Emoji = 'emoji',
}

export type TerminalElement = {
  type: TerminalElementType
  content: string
  href?: string
  mailto?: boolean
}

/**
 * TerminalConfig - represents configuration object that must be provided 
 * during terminal initialization.
 * @param fontFamily - terminal text font style.
 * @param fontSize - terminal text font size.
 * @param fontColor - terminal text font color.

 * @param terminalId - terminal HTML element id.
 * @param terminalBgColor - terminal background color.
 * @param terminalSecondaryBgColor - terminal secondary background colo for blinking effect.
 * @param terminalHeight - terminal height.
 * @param terminalWidth - terminal width.
 * @param terminalBorder - terminal border (optional).
 * @param terminalBoxShadow - terminal box shadow (optional).
 * @param terminalPadding - terminal padding (optional).

 * @param terminalFrameRate - terminal frame rate is the speed for characters to be added.

 * @param paragraphMargin - paragraph margin, if margin bottom is required.

 * @param outputText - output text, what terminal should spit out, 
 * consists of special terminal element objects.
 * @param parentElement - parent element, where terminal will be rendered.
 */

type TerminalConfig = {
  fontFamily: string
  fontSize: string
  fontColor: string

  terminalId: string
  terminalBgColor: string
  terminalSecondaryBgColor: string
  terminalHeight: string
  terminalWidth: string
  terminalBorder?: string
  terminalBoxShadow?: string
  terminalPadding?: string
  terminalBlink: boolean

  terminalFrameRate: number

  paragraphMargin?: string

  outputText: Array<Array<TerminalElement>>
  parentElement: HTMLElement | null
}

export class Terminal {
  // persist passed config
  cfg: TerminalConfig
  // persist terminal element
  terminal: HTMLElement
  // persist timeouts
  timeouts: Array<number> = []
  // persist intervals
  intervals: Array<number> = []

  constructor(cfg: TerminalConfig) {
    this.cfg = cfg
  }

  private initTerminal() {
    // create terminal
    const t = document.createElement('div')
    t.id = this.cfg.terminalId

    // add terminal styles
    t.style.fontFamily = this.cfg.fontFamily
    t.style.fontSize = this.cfg.fontSize
    t.style.color = this.cfg.fontColor

    t.style.width = this.cfg.terminalWidth
    t.style.height = this.cfg.terminalHeight
    t.style.backgroundColor = this.cfg.terminalBgColor
    t.style.border = this.cfg.terminalBorder || ''
    t.style.boxShadow = this.cfg.terminalBoxShadow || ''
    t.style.padding = this.cfg.terminalPadding || ''

    // save terminal in state
    this.terminal = t
  }

  private fillTerminal() {
    // add terminal blinking effect
    if (this.cfg.terminalBlink) {
      const id = setInterval(() => {
        this.terminal.style.backgroundColor === this.cfg.terminalBgColor
          ? (this.terminal.style.backgroundColor = this.cfg.terminalSecondaryBgColor)
          : (this.terminal.style.backgroundColor = this.cfg.terminalBgColor)
      }, this.cfg.terminalFrameRate)
      this.intervals.push(id)
    }

    // loop over array of array of terminal elements in output text
    this.cfg.outputText.forEach((sentence, sentenceId) => {
      // create timeout to render paragraphs 1 by 1
      const id = setTimeout(() => {
        // create paragraph
        const paragraph = document.createElement('p')

        // add paragraph style
        paragraph.style.margin = this.cfg.paragraphMargin || ''

        // append terminal character
        paragraph.appendChild(this.createCharacter('> '))

        // append paragraph to child
        this.terminal.appendChild(paragraph)

        // re-render
        this.render()

        // loop over each terminal element in sentence
        sentence.forEach((termElement) => {
          switch (termElement.type) {
            case TerminalElementType.Text:
              // loop over each character of the sentence
              termElement.content.split('').forEach((char, charId) => {
                // create timeout to render characters 1 by 1
                const textTimeoutId = setTimeout(() => {
                  // create and append current character
                  paragraph.appendChild(this.createCharacter(char))

                  // re-render
                  this.render()
                }, charId * this.cfg.terminalFrameRate)

                // save timeouts for later cleanse
                this.timeouts.push(textTimeoutId)
              })
              break
            case TerminalElementType.Link:
              // create timeout to render link
              const linkTimeoutId = setTimeout(() => {
                // create and append current character
                paragraph.appendChild(
                  this.createLink(
                    termElement.content,
                    termElement.href || '',
                    termElement.mailto || false,
                  ),
                )

                // re-render
                this.render()
              }, (termElement.content.length / 2.5) * this.cfg.terminalFrameRate)

              // save timeouts for later cleanse
              this.timeouts.push(linkTimeoutId)
              break
            default:
              break
          }
        })
      }, this.countSentenceTimeout(sentenceId))

      // save timeouts for later cleanse
      this.timeouts.push(id)
    })
  }

  // counts delay for timeout of the sentence to start rendering
  private countSentenceTimeout(sentenceId: number): number {
    // multiply frame rate by length of all previous sentences (excluding current)
    let delay = 0

    // loop over each terminal element in sentence
    this.cfg.outputText.forEach((termElList, id) => {
      // if sentence id is less than current
      if (id < sentenceId) {
        // loop over each terminal element in sentence
        termElList.forEach((termEl) => {
          // if that element of type text
          if (termEl.type === TerminalElementType.Text) {
            // then just add it length to delay
            delay += termEl.content.length
          }
        })
      }
    })

    // return framerate times delay as timeout value for next sentence to start rendering
    return this.cfg.terminalFrameRate * delay
  }

  // creates span element and assign provided text content
  private createCharacter(char: string): HTMLElement {
    // create span
    const span = document.createElement('span')
    span.textContent = char
    return span
  }

  // create anchor link and assigns provided content
  private createLink(content: string, href: string, mailto: boolean): HTMLElement {
    // create link
    const a = document.createElement('a')
    a.textContent = content
    a.href = mailto ? 'mailto:' + href : href
    a.target = '_blank'
    a.rel = 'noopener noreferrer'
    return a
  }

  // render terminal in parent element
  private render() {
    if (this.cfg.parentElement === null) {
      throw 'Cannot render terminal when parent element is null.'
    }

    this.cfg.parentElement.appendChild(this.terminal)
  }

  // cleanup terminal inner html, all timeouts, and intervals
  public cleanTerminal() {
    if (this.terminal) {
      this.terminal.innerHTML = ''
      this.cfg.parentElement!.innerHTML = ''
    }
    this.timeouts.forEach((id) => clearTimeout(id))
    this.timeouts = []
    this.intervals.forEach((id) => clearInterval(id))
    this.intervals = []
  }

  // initializes terminal if not initialized and fill it with content
  public init() {
    if (!this.terminal) {
      this.initTerminal()
    }
    this.fillTerminal()
  }
}
