import { throttle } from './throttle'

// TODO: refactor

export const codeCursor = (function codeCursor() {
  const goReservedWords = [
    'break',
    'default',
    'func',
    'interface',
    'select',
    'case',
    'defer',
    'go',
    'map',
    'struct',
    'chan',
    'else',
    'goto',
    'package',
    'switch',
    'const',
    'fallthrough',
    'if',
    'range',
    'type',
    'continue',
    'for',
    'import',
    'return',
    'var',
  ]

  const jsReservedWords = [
    'abstract',
    'arguments',
    'await*',
    'boolean',
    'break',
    'byte',
    'case',
    'catch',
    'char',
    'class*',
    'const',
    'continue',
    'debugger',
    'default',
    'delete',
    'do',
    'double',
    'else',
    'enum*',
    'eval',
    'export*',
    'extends*',
    'false',
    'final',
    'finally',
    'float',
    'for',
    'function',
    'goto',
    'if',
    'implements',
    'import*',
    'in',
    'instanceof',
    'int',
    'interface',
    'let*',
    'long',
    'native',
    'new',
    'null',
    'package',
    'private',
    'protected',
    'public',
    'return',
    'short',
    'static',
    'super*',
    'switch',
    'synchronized',
    'this',
    'throw',
    'throws',
    'transient',
    'true',
    'try',
    'typeof',
    'var',
    'void',
    'volatile',
    'while',
    'with',
    'yield',
  ]

  const programmingSymbols = [
    '{',
    '}',
    '[',
    ']',
    '(',
    ')',
    ';',
    '=',
    ':=',
    '+',
    '-',
    '>',
    '<',
    '<=',
    '>=',
    '~',
    '±',
    '√',
    '∫',
  ]

  var possibleEmoji = [...goReservedWords, ...jsReservedWords, ...programmingSymbols]
  var width = window.innerWidth
  var height = window.innerHeight
  var cursor = { x: width / 2, y: width / 2 }
  var particles: Array<any> = []

  function init() {
    bindEvents()
    loop()
  }

  // Bind events that are needed
  function bindEvents() {
    document.addEventListener(
      'mousemove',
      throttle((e) => onMouseMove(e), 150),
    )
    document.addEventListener('touchmove', onTouchMove)
    document.addEventListener('touchstart', onTouchMove)

    window.addEventListener('resize', onWindowResize)
  }

  function onWindowResize(e) {
    width = window.innerWidth
    height = window.innerHeight
  }

  function onTouchMove(e) {
    if (e.touches.length > 0) {
      for (var i = 0; i < e.touches.length; i++) {
        addParticle(
          e.touches[i].clientX,
          e.touches[i].clientY,
          possibleEmoji[Math.floor(Math.random() * possibleEmoji.length)],
        )
      }
    }
  }

  function onMouseMove(e) {
    cursor.x = e.clientX
    cursor.y = e.clientY

    addParticle(
      cursor.x,
      cursor.y,
      possibleEmoji[Math.floor(Math.random() * possibleEmoji.length)],
    )
  }

  function addParticle(x, y, character) {
    var particle = new Particle()
    particle.init(x, y, character)
    particles.push(particle)
  }

  function updateParticles() {
    // Updated
    for (var i = 0; i < particles.length; i++) {
      particles[i].update()
    }

    // Remove dead particles
    for (var i = particles.length - 1; i >= 0; i--) {
      if (particles[i].lifeSpan < 0) {
        particles[i].die()
        particles.splice(i, 1)
      }
    }
  }

  function loop() {
    requestAnimationFrame(loop)
    updateParticles()
  }

  /**
   * Particles
   */

  function Particle() {
    this.lifeSpan = 120 //ms
    this.initialStyles = {
      position: 'fixed',
      left: 0,
      top: 0,
      display: 'inline-block',
      pointerEvents: 'none',
      'z-index': '10000000',
      fontSize: '2rem',
      'will-change': 'transform',
      'font-family': 'var(--font-secondary)',
      // 'mix-blend-mode': 'overlay',
    }

    // Init, and set properties
    this.init = function (x, y, character) {
      this.velocity = {
        x: (Math.random() < 0.5 ? -1 : 1) * (Math.random() / 2),
        y: 1,
      }

      this.position = { x: x - 10, y: y - 20 }

      this.element = document.createElement('span')
      this.element.innerHTML = character
      applyProperties(this.element, this.initialStyles)
      this.update()

      document.body.appendChild(this.element)
    }

    this.update = function () {
      this.position.x += this.velocity.x
      this.position.y += this.velocity.y
      this.lifeSpan--

      this.element.style.transform =
        'translate3d(' +
        this.position.x +
        'px,' +
        this.position.y +
        'px,0) scale(' +
        this.lifeSpan / 120 +
        ')'
    }

    this.die = function () {
      this.element.parentNode.removeChild(this.element)
    }
  }

  /**
   * Utils
   */

  // Applies css `properties` to an element.
  function applyProperties(target, properties) {
    for (var key in properties) {
      target.style[key] = properties[key]
    }
  }

  init()
})()
