// modules
import { Terminal } from './modules/terminal'
import { codeCursor } from './modules/codeCursor'
import { throttle } from './modules/throttle'

// handlers
import {
  Section,
  onScrollUp,
  onScrollDown,
  activateSection,
} from './modules/eventHandlers'

// settings
import { terminalSettings } from './modules/terminalSettings'

window.onload = () => {
  // current section
  let currentSection = Section.About

  // get sections
  const sectionAbout = document.getElementById('section-about')
  const sectionExpertise = document.getElementById('section-expertise')
  const sectionContacts = document.getElementById('section-contacts')

  // create terminals for each section and render first section terminal
  const terminalAbout = new Terminal(terminalSettings.about) // terminalAbout.init()
  const terminalExpertise = new Terminal(terminalSettings.expertise)
  const terminalContacts = new Terminal(terminalSettings.contacts)

  // using active classes defined in index.html file
  // in order to avoid layout shifts during initial website boot

  // set default section
  activateSection(Section.About, {
    terminalAbout,
    terminalExpertise,
    terminalContacts,
  })

  // on about section click
  sectionAbout?.addEventListener('click', (e) => {
    if (currentSection === Section.About) return

    activateSection(Section.About, {
      terminalAbout,
      terminalExpertise,
      terminalContacts,
    })
    currentSection = Section.About
  })

  // on expertise section click
  sectionExpertise?.addEventListener('click', (e) => {
    if (currentSection === Section.Expertise) return

    activateSection(Section.Expertise, {
      terminalAbout,
      terminalExpertise,
      terminalContacts,
    })
    currentSection = Section.Expertise
  })

  // on contacts section click
  sectionContacts?.addEventListener('click', (e) => {
    if (currentSection === Section.Contacts) return

    activateSection(Section.Contacts, {
      terminalAbout,
      terminalExpertise,
      terminalContacts,
    })
    currentSection = Section.Contacts
  })

  // on touch up and down - for mobile devices
  let touchStart
  document.addEventListener('touchstart', (e) => {
    touchStart = e.touches[0].clientY
  })
  document.addEventListener('touchend', (e) => {
    var touchEnd = e.changedTouches[0].clientY
    if (touchStart > touchEnd + 5) {
      // on swipe down change section to next
      currentSection = onScrollDown(currentSection, {
        terminalAbout,
        terminalExpertise,
        terminalContacts,
      })
    } else if (touchStart < touchEnd - 5) {
      // on swipe up change section to prev
      currentSection = onScrollUp(currentSection, {
        terminalAbout,
        terminalExpertise,
        terminalContacts,
      })
    }
  })

  // on scroll up and down - for computers
  window.onwheel = throttle((e) => {
    if (e.deltaY >= 0) {
      // on scroll up change section to prev
      currentSection = onScrollUp(currentSection, {
        terminalAbout,
        terminalExpertise,
        terminalContacts,
      })
    } else {
      // on scroll down change section to next
      currentSection = onScrollDown(currentSection, {
        terminalAbout,
        terminalExpertise,
        terminalContacts,
      })
    }
  }, 1000)

  // run code cursor
  codeCursor
}
